/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap');

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  body {
    @apply m-0 font-sans bg-hyc-dark text-hyc-light text-xl font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    @apply font-system;
  }
  
  h1 {
    @apply font-mono text-title leading-title whitespace-nowrap overflow-hidden text-hyc-gold p-4;
  }
  
  h2 {
    @apply font-mono text-5xl text-hyc-light mb-2 text-center;
  }
  
  h3.subtitle {
    @apply font-mono text-2xl text-hyc-light mb-5 text-center;
  }
  
  h4 {
    @apply font-mono text-xl text-hyc-light mb-2;
  }
}

/* Component styles */
@layer components {
  /* Legacy style overrides using new theme variables */
  .text-honor {
    @apply text-hyc-gold underline;
  }
  
  .text-and,
  .text-org {
    @apply text-hyc-light;
  }
  
  .text-coima {
    @apply text-hyc-accent underline;
  }
}

h2 {
  font-family: 'PT Mono', monospace; /* Use PT Mono font */
  font-size: 3rem; /* Larger font size */
  color: #93c7ab; /* Light green color for main text */
  margin-bottom: 10px; /* Adjusted margin */
  text-align: center; /* Center align */
}

h3.subtitle {
  font-family: 'PT Mono', monospace; /* Use PT Mono font */
  font-size: 1.5rem; /* Smaller font size for subtitle */
  color: #93c7ab; /* Light green color for main text */
  margin-bottom: 20px; /* Margin below subtitle */
  text-align: center; /* Center align */
}

h2 .text-underscore {
  @apply text-[#93c7ab] underline;
}

h1 .text-and,
h1 .text-org {
  @apply text-[#93c7ab];
}

h1 .text-coima {
  @apply text-[#DC5A0B];
}

.text-honor {
  color: #bfa343; /* Color for Honor, y, y .org */
  text-decoration: underline;
}

.text-and,
.text-org {
  color: #93c7ab; /* Color for y y .org */
}

.text-coima {
  color: #DC5A0B; /* Color for Coima */
  text-decoration: underline;
}

/* Updated styles for the registration form */
.form-container {
  max-width: 1200px; /* Increased max-width for wider view */
  margin: 0 auto;
  padding: 60px;
  border-radius: 8px;
  color: #93c7ab;  /* Main Text Color */
}

.form-container h2 {
  color: #93c7ab;  /* Light green color for main text */
  margin-bottom: 20px; /* Adjusted margin */
}

.form-container h3.subtitle {
  color: #93c7ab;  /* Light green color for subtitle */
  margin-bottom: 20px; /* Margin below subtitle */
}

.form-group {
  margin-bottom: 2px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.form-group.full-width {
  grid-column: span 2;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  margin-top: 16px;
  color: #93c7ab;  /* Light green color for main text */
}

.form-group input,
.form-group select,
.form-group textarea,
.react-datepicker-wrapper,
.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
  background-color: #142723;  /* Match background */
  color: #93c7ab;  /* Main Text Color */
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus,
.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #61dafb;
}

.form-group button {
  width: 100%;
  padding: 16px;
  margin-top: 24px;
  background-color: #DC5A0B;  /* Hard Highlight Text */
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
}

.info-container {
  font-size: 0.8rem;
  color: #bfa343;
}


.form-group button:hover {
  background-color: #a33c32;  /* Darker Hard Highlight Text */
}

.form-group .link-input-group {
  display: flex;
  align-items: center;
}

.form-group .link-input-group .link-input {
  flex-grow: 1;
  margin-right: 10px;
}

.form-group .link-input-group .remove-link-button,
.form-group .add-link-button {
  background: none;
  border: none;
  color: #DC5A0B;  /* Hard Highlight Text */
  cursor: pointer;
}

.form-group .add-link-button:hover,
.form-group .link-input-group .remove-link-button:hover {
  color: #93c7ab;
}

.checkbox-group {
  display: flex;
  gap: 10px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  color: #93c7ab;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px;
}

.tipo-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: #93c7ab;
  color: #142723;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.tag.selected {
  background-color: #DC5A0B;
  color: #fff;
}

.tag:hover {
  background-color: #bfa343;
}

.form-select {
  background-color: #142723;
  color: #93c7ab;
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 8px; /* Add spacing between select elements */
}

.form-select:focus {
  border-color: #61dafb;
  outline: none;
}

/* Custom styles for react-datepicker */
.react-datepicker {
  background-color: #142723 !important;
  border: 1px solid #ccc;
  color: #93c7ab;
}

.react-datepicker__header {
  background-color: #1e2a26 !important;
  border-bottom: 1px solid #ccc;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #93c7ab !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #DC5A0B !important;
  color: white !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #a33c32 !important;
}

.react-datepicker__day:hover {
  background-color: #1e2a26;
  color: #93c7ab;
}

.react-datepicker__input-container input {
  background-color: #142723;
  color: #93c7ab;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  margin: 0 10px;
  display: inline-block;
}

.react-datepicker__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-datepicker__month-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  background-color: #142723;
}

.react-datepicker__time-container .react-datepicker__time-list-item {
  color: #93c7ab;
  background-color: #142723;
}

.react-datepicker__time-container .react-datepicker__time-list-item:hover {
  background-color: #bfa343;
  color: #142723;
}

.react-datepicker__time-container .react-datepicker__time-list-item--selected {
  background-color: #DC5A0B;
  color: #fff;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

@media (min-width: 1024px) {
  .form-grid {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.datepicker-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  cursor: pointer;
  color: #93c7ab;
}

.custom-date-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #93c7ab;
  border-radius: 4px;
  background-color: #142723;
  color: #93c7ab;
  cursor: pointer;
}

.custom-date-input:hover {
  background-color: #bfa343;
  color: #142723;
}

.error {
  color: #DC5A0B; /* Color de error */
  margin-top: 10px;
}

.info-text {
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #93c7ab;
}

.info-icon {
  margin-left: 5px;
  color: #bfa343;
  cursor: pointer;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--next--with-time,
.react-datepicker__navigation--previous {
  display: none !important;
}

.react-datepicker__time-container .react-datepicker-time__header {
  color: #93c7ab !important; /* Light green color */
}

/* Custom marker styles for map */
.red-marker .leaflet-marker-icon {
  filter: hue-rotate(0deg) saturate(200%);
}

.orange-marker .leaflet-marker-icon {
  filter: hue-rotate(30deg) saturate(200%);
}

.yellow-marker .leaflet-marker-icon {
  filter: hue-rotate(60deg) saturate(200%);
}

.blue-marker .leaflet-marker-icon {
  filter: hue-rotate(240deg) saturate(200%);
}

.purple-marker .leaflet-marker-icon {
  filter: hue-rotate(270deg) saturate(200%);
}

/* Modal Styles */
.map-modal {
  @apply rounded-lg shadow-lg overflow-hidden;
}

.map-modal-overlay {
  @apply fixed inset-0 bg-black/70 flex items-center justify-center z-50;
}

/* Leaflet popup styles */
.leaflet-popup-content-wrapper {
  @apply rounded-lg shadow-lg;
}

.leaflet-popup-content {
  @apply p-1 m-0;
}
