.splash-container {
  text-align: center;
  margin-top: 50px;
  width: 100%;
  color: #93c7ab; /* Main Text Color */
  padding: 20px;
  background: linear-gradient(135deg, #142723, #1e2a26); /* Background gradient */
}

.splash-container h1 {
  font-family: 'PT Mono', monospace; /* Set the PT Mono font */
  font-size: calc(24vw * 0.24);
  line-height: calc(5vw * 0.05);
  white-space: nowrap; /* Ensures the text stays in one line */
  overflow: hidden; /* Prevents overflow */
  color: #bfa343; /* Default text color */
  text-align: center;
  padding: 4%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Text shadow for modern look */
}

.splash-container h2 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: calc(5vw * 0.35);
  line-height: calc(2vw * 0.02);
  color: #93c7ab; /* Default text color */
  text-align: center;
  padding: 4%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Text shadow for modern look */
}

.splash-container h2 .text-underscore {
  color: #93c7ab; /* Color for Honor, y, and .org */
  text-decoration: underline;
}

.splash-container h1 .text-and,
.splash-container h1 .text-org {
  color: #93c7ab; /* Color for Honor, y, and .org */
}

.splash-container h1 .text-coima {
  color: #DC5A0B; /* Color for Coima */
}

.splash-container .content {
  font-family: 'Work Sans', sans-serif;
  text-align: left;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
}

.splash-container .content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 10px 0;
}

.splash-container .content .subtitle {
  color: #DC5A0B; /* Subtitles color */
  text-align: center;
  margin-top: 20px; /* Add margin to separate subtitles */
}

.splash-container .content .section {
  margin-bottom: 40px; /* Add space between sections */
}

.splash-container .content ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 1rem;
}

.splash-container .text-honor {
  color: #bfa343; /* Color for Honor, y, y .org */
  text-decoration: underline;
}

.splash-container .text-and,
.splash-container .text-org {
  color: #93c7ab; /* Color for y y .org */
}

.splash-container .text-coima {
  color: #DC5A0B; /* Color for Coima */
  text-decoration: underline;
}

.splash-container .logo-text {
  font-family: 'PT Mono', monospace; /* Font for the logo text */
}

.splash-container .bottom-text {
  font-size: 1.2rem;
  text-align: center;
  margin: 20px 0;
}

.splash-container .contact-email {
  color: #DC5A0B; /* Color for email */
}

@media (max-width: 768px) {
  .splash-container h1 {
    font-size: calc(28vw * 0.28); /* 90% of viewport width on mobile */
    line-height: calc(3vw * 0.03);
    text-align: center;
    padding: 10%;
  }

  .splash-container h2 {
    font-size: 1.2rem; /* Match the description text size */
    line-height: 1.5;
    padding: 4%;
  }

  .splash-container .content {
    font-size: 1rem;
  }

  .splash-container .content .section {
    margin-bottom: 20px; /* Adjust spacing for smaller screens */
  }
}
