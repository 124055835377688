.subtitle {
    font-family: 'PT Mono', monospace; /* Use PT Mono font */
    font-size: 1.5rem; /* Smaller font size for subtitle */
    text-align: center; /* Center align */
    margin-bottom: 20px; /* Margin below subtitle */
  }
  
  .text-honor {
    color: #bfa343; /* Color for Honor */
    text-decoration: underline;
  }
  
  .text-and,
  .text-org {
    color: #93c7ab; /* Color for y and .org */
  }
  
  .text-coima {
    color: #DC5A0B; /* Color for Coima */
    text-decoration: underline;
  }
  