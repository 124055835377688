.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.lightbox-content {
  background: #1a2622;
  border-radius: 8px;
  color: #93c7ab;
  max-width: 85%;
  max-height: 85vh;
  width: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(147, 199, 171, 0.2);
}

.lightbox-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: background 0.2s;
}

.lightbox-close:hover {
  background: rgba(0, 0, 0, 0.6);
}

.lightbox-title {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.lightbox-media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;
}

.lightbox-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 85vh;
}

.lightbox-image {
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
}

.lightbox-video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  background: #000;
}

.lightbox-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lightbox-audio-container {
  padding: 40px 20px 20px;
  width: 100%;
  max-width: 500px;
}

.lightbox-audio-title {
  margin-bottom: 12px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.lightbox-audio {
  width: 100%;
}

.lightbox-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.lightbox-nav-button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s;
}

.lightbox-nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.lightbox-counter {
  color: white;
  font-size: 14px;
}
  