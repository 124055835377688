.confirmation-message {
  background-color: rgba(191, 163, 67, 0.15);
  border: 1px solid rgba(191, 163, 67, 0.3);
  color: #bfa343;
  margin-top: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.error {
  background-color: rgba(220, 38, 38, 0.15);
  border: 1px solid rgba(220, 38, 38, 0.3);
  color: #f87171;
  margin-top: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
}